<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-6"><h5>Статус смены</h5></div>
                <div class="col-6 text-right" style="white-space: nowrap;">
                    <router-link class="btn btn-primary d-none d-lg-block d-md-block ml-2" style="display: inline-block !important;"
                                 :to="{name: 'ProductionTaskListPlans'}">
                        <i class="fas fa-print"></i> Печать по продукции
                    </router-link>
                    <div class="btn-group">
                        <button title="Инвентаризация" class="btn btn-lg" @click="toInventoryAct"><i class="fas fa-clipboard-check"></i></button>
                        <button class="btn btn-lg" disabled><i class="fas fa-angle-double-left"></i> <i class="fas fa-lock"></i></button>
                        <button title="Закрытие смены" class="btn btn-lg"
                                :disabled="loadingShiftClosingAct"
                                @click="blockShift">
                            <i :class="{'fa-spinner fa-spin': loadingShiftClosingAct, 'fa-lock': !loadingShiftClosingAct}"
                               class="fa"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body" style="overflow-y: auto; overflow-x: hidden; height: calc(100vh - 220px);">
            <div class="card">
                <div class="card-header">
                    <h4 v-b-toggle.material-transfers class="text-center">Перемещения</h4>
                </div>
                <b-collapse visible id="material-transfers">
                    <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <button @click="loadNotReceivedTransfers" class="btn btn-light btn-block">
                                        <i
                                            class="fas fa-sync"
                                            :class="{'fa-spin': notReceivedTransfersBeingLoaded}"
                                        />
                                        Принять
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button @click="loadNotTransferredProducedLots" class="btn btn-light btn-block">
                                        <i
                                            class="fas fa-sync"
                                            :class="{'fa-spin': notTransferredProducedLotsBeingLoaded}"
                                        />
                                        Передать
                                    </button>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-6">
                                    <div>
                                        <div v-if="notReceivedTransfersBeingLoaded" class="text-center">
                                            <div class="alert alert-info" style="display: inline-block"><i class="fas fa-spinner fa-spin"></i></div>
                                        </div>
                                        <template v-else>
                                            <div v-if="!notReceivedTransfers.length" class="alert alert-light text-center">Нет передач</div>
                                            <div v-else>
                                                <not-received-transfer
                                                    :key="act.id"
                                                    v-for="act in notReceivedTransfers"
                                                    :transfer="act"
                                                />
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div v-if="notTransferredProducedLotsBeingLoaded" class="text-center">
                                        <div class="alert alert-info" style="display: inline-block"><i class="fas fa-spinner fa-spin"></i></div>
                                    </div>
                                    <template v-else>
                                        <div v-if="notTransferredProducedLots.length === 0" class="alert alert-light text-center">Нет партий</div>
                                        <div v-else
                                             v-for="lot in notTransferredProducedLots"
                                             :class="{'alert-success': !!lot.transferred, 'alert-primary': !(!!lot.transferred)}"
                                             class="alert mb-1" @click="transfer(lot)">
                                            <span>{{lot.nomenclature.name}}</span>
                                            <br>
                                            <span>{{lot.items[0].count}} {{lot.nomenclature.measure_unit.name}}</span>
                                            <span v-if="!(!!lot.transferred)" class="pull-right">
                                в {{lot.storageTo.name}}
                                <i :class="{'fa-spinner fa-spin': !!lot.transferring, 'fa-angle-double-right': !(!!lot.transferring)}"
                                   class="fa"></i>
                            </span>
                                        </div>
                                    </template>
                                </div>
                            </div>
                    </div>
                </b-collapse>
            </div>

            <div class="card">
                <div class="card-header">
                    <h4 v-b-toggle.shift-half-stuff-required class="text-center">Полуфубрикаты на смену</h4>
                </div>
                <b-collapse visible id="shift-half-stuff-required">
                    <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <shift-half-stuff-required-list
                                        :actual-shift="actualShift"
                                        :storage="storage"
                                        :rests="rests ? rests : {}"
                                    />
                                </div>
                            </div>
                    </div>
                </b-collapse>
            </div>

            <div class="card">
                <div class="card-header">
                    <h4 v-b-toggle.storage-tasks class="text-center">Сырье на смену</h4>
                </div>
                <b-collapse visible id="storage-tasks">
                <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <storage-task-list
                                    :shift="actualShift.shift"
                                    :date="moment(actualShift.date).format('YYYY-MM-DD')"
                                    :storage-to="storage"
                                    :not-delivered-only="false"
                                    :allow-transfer="false"
                                    :show-filters="false"
                                />
                            </div>
                        </div>
                </div>
                </b-collapse>
            </div>
        </div>
    </div>
</template>

<script>
import StorageTaskList                                              from "../StorageTaskList";
import NotReceivedTransfer                                          from "./NotReceivedTransfer";
import ShiftHalfStuffRequiredList                                   from "../ShiftHalfStuffRequiredList";
import {MATERIAL_TRANSFER_ACT_ENDPOINT, SHIFT_CLOSING_ACT_ENDPOINT} from "@utils/endpoints";
import BarcodeRepository                                            from "@utils/BarcodeRepository";
import moment                                                       from 'moment';
import {mapState}                                                   from "vuex";
import ErrorHandler                                                 from "./ErrorHandler";

export default {
    name: "ShiftStats",
    components: {
        StorageTaskList,
        NotReceivedTransfer,
        ShiftHalfStuffRequiredList,
    },
    computed: {
        ...mapState({
            notReceivedTransfersBeingLoaded: state => state.storage_senior_dashboard.notReceivedTransfersBeingLoaded,
            notReceivedTransfers: state => state.storage_senior_dashboard.notReceivedTransfers,
            notTransferredProducedLots: state => state.storage_senior_dashboard.notTransferredProducedLots,
            notTransferredProducedLotsBeingLoaded: state => state.storage_senior_dashboard.notTransferredProducedLotsBeingLoaded,
            storage: state => state.storage_senior_dashboard.storage,
            actualShift: state => state.storage_senior_dashboard.actualShift,
            rests: state => state.storage_senior_dashboard.rests,
        })
    },
    data() {
        return {
            loadingShiftClosingAct: false,
            shiftClosingAct: null,
        };
    },
    watch: {
        materialTransferActToReceive(val) {
            if (val) {
                this.$modal.show('MaterialTransferReceiveForm');
            } else {
                this.$modal.hide('MaterialTransferReceiveForm');
            }
        },
        storage() {
            this.init();
        },
        actualShift() {
            this.init();
        },
    },
    methods: {
        moment,
        transfer(lot) {

            if (!!lot.transferred || !!lot.transferring) {
                return;
            }

            if (!confirm('Передать ' + lot.nomenclature.name + '?')) {
                return;
            }

            lot.items[0].box = {name: BarcodeRepository.boxPrefixes[1] +
                    Math.floor( Math.random() * ( 99999 - 10000 + 1 ) + 10000 ).toString()};

            lot.storage_from_id = this.storage.id;
            lot.storage_to_id = lot.storageTo.id;
            lot.transferring = true;
            this.$forceUpdate();

            this.$http.post(MATERIAL_TRANSFER_ACT_ENDPOINT, lot, {params: {without_loading: true}})
            .then(response => {
                lot.transferred = true;
                this.$store.dispatch('storage_senior_dashboard/loadTasks');
            }).catch(response => {
                ErrorHandler.assert(response, 'Ошибка передачи');
            }).finally(() => {
                lot.transferring = false;
                this.$forceUpdate();
            });
        },
        loadNotTransferredProducedLots() {
            this.$store.dispatch('storage_senior_dashboard/loadNotTransferredProducedLots');
        },
        loadNotReceivedTransfers() {
            this.$store.dispatch('storage_senior_dashboard/loadNotReceivedTransfers', this.storage);
        },
        blockShift() {
            if (this.loadingShiftClosingAct) {
                return;
            }

            this.loadingShiftClosingAct = true;
            this.$http.get(SHIFT_CLOSING_ACT_ENDPOINT + `/of-actual-shift/${this.actualShift.id}/${this.storage.id}`, {
                params: {
                    without_loading: true,
                }
            }).then(response => {
                this.shiftClosingAct = response.data;

                this.$router.push({
                    name: response.data.step === null ? 'ShiftClosingActUpdate' : 'ShiftClosingWizard',
                    params: {
                        id: response.data.id,
                    }
                });
            }).catch(response => {

                if (response.status !== 404) {
                    ErrorHandler.assert(response, 'Ошибка получения акта закрытия смены');
                    return;
                }

                this.$router.push({
                    name: 'ShiftClosingWizard',
                    params: {
                        storage: this.storage,
                        actualShift: this.actualShift
                    }
                });
            }).finally(() => {
                this.loadingShiftClosingAct = false;
            });
        },
        toInventoryAct() {
            this.$router.push({
                name: 'InventoryActCreate',
                params: {
                    storage: this.storage,
                }
            });
        },
        init() {
            this.loadNotTransferredProducedLots();
            this.loadNotReceivedTransfers();
        },
    },
    mounted() {
        this.init();
    },
}
</script>
