<template>
    <b-row class="d-flex justify-content-center">
        <b-col cols="6" v-show="showed">
            <h4 class="text-center">Температура после шоковой камеры</h4>
            <b-table
                :items="items"
                :fields="fields"
                head-variant="dark"
                small
                bordered
            />
        </b-col>
    </b-row>
</template>

<script>

export default {
    name: 'TemperatureAfterShockTable',
    props: {
        productionActItems: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            showed: false,
            fields: [
                {
                    key: 'temperature_after_shock_1',
                    label: 'Партия 1',
                },
                {
                    key: 'temperature_after_shock_2',
                    label: 'Партия 2',
                },
                {
                    key: 'temperature_after_shock_3',
                    label: 'Партия 3',
                },
                {
                    key: 'temperature_after_shock_4',
                    label: 'Партия 4',
                },
                {
                    key: 'temperature_after_shock_5',
                    label: 'Партия 5',
                },
                {
                    key: 'temperature_after_shock_6',
                    label: 'Партия 6',
                },

            ],
        }
    },
    computed: {
        items() {
            let item = {};
            this.showed = false;
            if (this.productionActItems.length) {
                this.productionActItems.forEach((productionActItem, index) => {
                    if (productionActItem.temperature_after_shock) {
                        this.showed = true;
                        const key = `temperature_after_shock_${(index + 1)}`;

                        item[key] = productionActItem.temperature_after_shock;
                    }
                });
            }

            return [item];
        }
    },
}
</script>
