<template>
    <div>
        <div v-if="loading" class="text-center p-2" style="width: 100%">
            <div class="alert alert-info" style="display: inline-block"><i class="fas fa-spinner fa-spin"></i></div>
        </div>
        <table v-else-if="Object.keys(data).length" class="table table-sm table-bordered">
            <tbody>
            <template v-for="datum in data">
                <tr style="background-color: #23282c; color: #e4e5e6">
                    <td>{{datum.storage.name}} <i class="fas fa-long-arrow-alt-right p-1"></i> {{storage.name}}</td>
                    <td class="text-right">Требуется</td>
                    <td class="text-right">В наличии</td>
                </tr>
                <tr v-for="item in datum.items">
                    <td>{{item.nomenclature.name}}</td>
                    <td class="text-right">{{parseFloat(item.count).toFixed(3)}} {{item.nomenclature.measure_unit.name}}</td>
                    <td class="text-right">{{parseFloat(!!rests[item.nomenclature.id] ? rests[item.nomenclature.id].count : 0).toFixed(3)}} {{item.nomenclature.measure_unit.name}}</td>
                </tr>
            </template>
            </tbody>
        </table>
        <div v-else>
            <div class="alert alert-info text-center">нет данных</div>
        </div>
    </div>


</template>

<script>
    import {PRODUCTION_TASKS_ENDPOINT} from "../../utils/endpoints";

    export default {
        name: "ShiftHalfStuffRequiredList",
        props: {
            actualShift: {
                type: Object,
                required: true
            },
            storage: {
                type: Object,
                required: true
            },
            rests: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                data: [],
                loading: false,
            };
        },
        watch: {
            storage(val) {
                this.load();
            },
            actualShift(val) {
                this.load();
            },
        },
        methods: {
            load() {
                if (this.loading) {
                    return;
                }

                this.loading = true;

                this.$http.get(PRODUCTION_TASKS_ENDPOINT + '/required_half_stuff', {
                    params: {
                        actual_shift_id: this.actualShift.id,
                        storage_id: this.storage.id,

                        without_loading: true,
                    }
                }).then(response => {
                    this.data = response.data;
                }).catch(response => {
                    this.$toast.error(`Полуфабрикаты на смену: ${response.data.message}`);
                }).finally(() => {
                    this.loading = false;
                });
            },
        },
        mounted() {
            this.load();
        },
    }
</script>

<style scoped>

</style>
