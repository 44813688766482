<template>
    <div @click="doTransfer()"
        class="alert alert-primary mb-1">
        <div class="with-ellipsis">{{nomenclatureList.join(', ')}}</div>
        <div class="text-right">
            <i class="fas fa-angle-double-left"></i> из {{transfer.storageFrom.name}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "NotReceivedTransfer",
        props: {
            transfer: {
                type: Object,
                required: true
            }
        },
        data() {
            return {};
        },
        computed: {
            nomenclatureList() {
                return this.transfer.items.map(i => i.nomenclatureLot.nomenclature.name);
            },
        },
        methods: {
            doTransfer() {
                this.$store.commit('storage_senior_dashboard/set_transfer_to_receive', this.transfer);
            }
        }
    }
</script>

<style scoped>
    .with-ellipsis {
        width: 100%;
        overflow-x: hidden;
        word-break: keep-all;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>
