<template>
    <div class="card mb-0"
         :style="{'height': minimized ? '100%' : 'unset'}"
         style="border: none; background: transparent">
        <div v-show="minimized" style="height: 100%" class="pb-2">
            <button class="btn btn-outline-primary btn-lg" style="height: 100%" @click="minimized = false"><i class="fas fa-angle-double-right"></i></button>
        </div>
        <div v-show="!minimized">
            <div class="card-header">
                <div class="row">
                    <b-form-group description="Статус" class="mb-0 col-4 p-1">
                        <select v-model="status" class="form-control">
                            <option :value="null">Все ({{tasks.length}})</option>Xtn
                            <option value="in_progress">В работе ({{statusInfo.inProgress}})</option>
                            <option value="delayed">Опаздывающие ({{statusInfo.delayed}})</option>
                            <option value="next">Следующие ({{statusInfo.next}})</option>
                            <option value="finished">Завершенные ({{statusInfo.finished}})</option>
                        </select>
                    </b-form-group>
                    <b-form-group class="col-5 p-1">
                        <b-form-input v-model="filters.taskNomenclatureName" placeholder="Поиск..." />
                    </b-form-group>
                    <b-form-group class="col-2 p-1">
                        <button v-b-modal.filters class="btn btn-default"><i class="fas fa-sliders-h"></i>
                            <template v-if="filters.plans.length > 0">
                                {{ filters.plans.length > 9 ? '(9+)' : '(' + filters.plans.length + ')' }}
                            </template>
                        </button>
                    </b-form-group>
                    <b-form-group class="col-1 p-1">
                        <button class="btn btn-default" @click="minimized = true"><i class="fas fa-angle-double-left"></i></button>
                    </b-form-group>
                </div>
                <div  class="row">
                    <div v-if="hasAccessToBatchProduce && tasks.some(task => !task.productionActs.length)" class="col-1">
                        <input v-model="selectAll" type="checkbox" :disabled="isLoading">
                    </div>
                    <div v-show="batchSelection" class="col-3">
                        <button v-show="batchSelection" @click="clearSelection" class="btn btn-sm btn-ghost"><i class="fas fa-times"></i> Очистить выбор</button>
                    </div>
                    <div
                        v-if="hasAccessToBatchProduce && hasSelectedTasks"
                        class="col-6"
                    >
                        <button @click="produceSelected" :disabled="isLoading" class="btn btn-sm btn-danger">
                            <span v-if="isLoading" class="spinner-border spinner-border-sm"></span>
                            Завершить выбранные задания
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-1" style="position: sticky; top: 140px; height: calc(100vh - 237px); overflow-y: scroll; background: transparent; padding: .25rem 0 0 0 !important;">
                <div v-if="tasksBeingLoaded" class="dashboard-flex">
                    <div class="alert alert-info"><i class="fas fa-spinner fa-spin"></i></div>
                </div>
                <div v-else>
                    <div v-if="tasks.length === 0" class="alert alert-light text-center">Нет задач</div>
                    <div
                        v-for="(task, index) in filteredTasks"
                        :key="index"
                        v-if="taskPassesFilter(task, index) && (!batchSelection || (batchSelection && taskSelectable(task, index)))"
                        class="card task mb-2 ml-1"
                        :class="{'task-multiple': task.ids.length > 1}"
                    >
                        <div v-show="hasAccessToBatchProduce && !task.productionActs.length" class="card-header">
                            <input type="checkbox" v-model="task.selected" :disabled="isLoading">
                        </div>
                        <div
                            :class="taskClass(task, index)"
                            class="card-body w-100 d-flex"

                            v-long-press="touchLength"
                            @long-press-start="selectTask($event, task, index, true)"
                            @touchmove="touchMove(true)"
                            @touchend="touchMove(false)"
                            @click="selectTask($event, task, index)"
                            :disabled="isLoading"
                        >
                            <div class="task-left">
                                <div class="task-title mb-1">{{task.nomenclature.name}}</div>
                                <div class="mb-1">
                                    <div class="border-bottom-dashed">{{targetProductString(task)}}</div>
                                    <div>{{task.operation.name}}</div>
                                    <div>
                                        <span v-if="!!task.parent"><i class="fas fa-angle-double-right"></i> в {{task.parent.storage.name}}</span>
                                        <span v-if="task.fromPreviousShift"><b>(с прошлой смены)</b></span>
                                        <span v-if="task.fromBeforeLastShift"><b>(с позапрошлой смены)</b></span>
                                    </div>
                                </div>
                                <div v-if="hasNonAutoPlan(task)" style="white-space: nowrap; text-overflow:ellipsis; overflow:hidden;" class="alert alert-info p-0 pl-1 mb-1 mr-1">
                                        {{planReasonString(task)}}
                                </div>
                                <div class="task-info">
                                    <div class="task-timing mr-1">
                                        <span :class="startTimeClass(task)">{{startTime(task)}}</span>-<span :class="endTimeClass(task)">{{endTime(task)}}</span>
                                    </div>
                                    <template>
                                        <div v-if="restsBeingLoaded" class="task-alert"><i class="fas fa-spinner fa-spin"></i> проверка сырья...</div>
                                        <div v-else-if="hasMissingRawMaterials(task)" class="task-alert alert-danger">Не хватает сырья</div>
                                    </template>
                                </div>
                            </div>
                            <div class="task-right">
                                <div class="task-status text-right mb-1">{{taskStatus(task)}}</div>
                                <div class="task-amount text-right">{{producedCount(task).toFixed(3)}}&nbsp;{{task.nomenclature.measure_unit.name}}</div>
                                <div class="text-right">из</div>
                                <div class="task-amount text-right">{{parseFloat(task.count).toFixed(3)}}&nbsp;{{task.nomenclature.measure_unit.name}}</div>
                                <template v-if="(parseFloat(task.count) - producedCount(task)).toFixed(3) < 0">
                                    <div class="text-right">перевыпущено:</div>
                                    <div class="task-amount text-right">{{Math.abs(parseFloat(task.count) - producedCount(task)).toFixed(3)}} {{task.nomenclature.measure_unit.name}}</div>
                                </template>
                                <template v-else>
                                    <div class="text-right">осталось:</div>
                                    <div class="task-amount text-right">{{Math.abs(parseFloat(task.count) - producedCount(task)).toFixed(3)}} {{task.nomenclature.measure_unit.name}}</div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="filters" size="lg" hide-footer scrollable>
            <b-row>
                <b-col cols="12">
                    <b-form-input type="search" v-model="filters.planNomenclatureName" placeholder="Поиск..." />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <table class="table table-sm table-striped mb-0">
                        <thead class="thead-dark">
                        <tr>
                            <th></th>
                            <th>Наименование</th>
                            <th class="text-right">Отгрузка</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(plan, id) in filteredPlans" :key="id">
                            <td><b-checkbox :checked="filters.plans.some(i => i===id)" @change="togglePlanFilter(id, $event)"></b-checkbox></td>
                            <td @click="togglePlanFilter(id, !filters.plans.some(i => i===id))">
                                <div>{{plan.nomenclature.name}}</div>
                                <div v-if="plan.reason.name !== 'auto'" class="alert alert-info p-0">{{plan.reason.comment}}</div>
                            </td>
                            <td @click="togglePlanFilter(id, !filters.plans.some(i => i===id))" class="text-right" style="width: 150px;">{{moment(plan.date).format('DD.MM.YYYY HH:mm')}}</td>
                        </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
    import {mapState}                 from 'vuex';
    import moment                     from 'moment';
    import {sumBy}                    from 'lodash';
    import LongPress                  from 'vue-directive-long-press';
    import BarcodeRepository          from '@utils/BarcodeRepository.js';
    import {PRODUCTION_ACTS_ENDPOINT} from '@utils/endpoints.js';

    const DEFROST_VEGETABLE_STORAGE_ID = 7;
    const DEFROST_MEAT_STORAGE_ID = 16;

    export default {
        name: "TaskList",
        directives: {
            'long-press': LongPress
        },
        data() {
            return {
                status: null,

                minimized: false,
                loadingBarrier: 0,
                selectionTimeout: null,
                batchSelection: false,
                selectedIndexes: {},

                scrolling: false,
                scrollingTimeout: null,
                touchLength: 400,
                isLoading: false,
            };
        },
        computed: {
            filteredTasks() {
                const taskNomenclatureName = this.filters.taskNomenclatureName.trim().toLowerCase()
                    if (taskNomenclatureName.length > 0) {
                        return Object.keys(this.tasks).reduce((filteredTasks, key) => {
                            if (this.tasks[key].nomenclature.name.toLowerCase().includes(taskNomenclatureName)) {
                                filteredTasks[key] = this.tasks[key]
                            }
                            return filteredTasks
                        }, {})
                    }
                    return this.tasks
            },
            filteredPlans() {
                const planNomenclatureName = this.filters.planNomenclatureName.trim().toLowerCase()
                if (planNomenclatureName.length > 0) {
                    return Object.keys(this.plans).reduce((filteredPlans, key) => {
                        if (this.plans[key].nomenclature.name.toLowerCase().includes(planNomenclatureName)) {
                            filteredPlans[key] = this.plans[key]
                        }
                        return filteredPlans
                    }, {})
                }
                return this.plans
            },
            ...mapState({
                actualShift: state => state.storage_senior_dashboard.actualShift,
                tasksBeingLoaded: state => state.storage_senior_dashboard.tasksBeingLoaded,
                tasks: state => state.storage_senior_dashboard.tasks,
                restsBeingLoaded: state => state.storage_senior_dashboard.restsBeingLoaded,
                storage: state => state.storage_senior_dashboard.storage,
                plans: state => state.storage_senior_dashboard.plans,
                filters: state => state.storage_senior_dashboard.filters,
                shiftClosingAct: state => state.storage_senior_dashboard.shiftClosingAct,
            }),
            statusInfo() {
                let info = {
                    inProgress: 0,
                    delayed: 0,
                    next: 0,
                    finished: 0,
                };

                this.tasks.map(t => {
                    switch (this.statusCode(t)) {
                        case 'in_progress':
                            info.inProgress++;
                            break;
                        case 'delayed':
                            info.delayed++;
                            break;
                        case 'next':
                            info.next++;
                            break;
                        case 'finished':
                            info.finished++;
                            break;
                    }
                });

                return info;
            },
            hasAccessToBatchProduce() {
                return [DEFROST_MEAT_STORAGE_ID, DEFROST_VEGETABLE_STORAGE_ID].includes(this.storage.id);
            },
            selectAll: {
                get: function () {
                    return this.tasks.every(task => task.selected);
                },
                set: function (value) {
                    const tasks = [];
                    for (const task of this.tasks) {
                        tasks.push({
                            ...task,
                            selected: value,
                        });
                    }

                    this.$store.commit('storage_senior_dashboard/set_tasks', tasks);
                },
            },
            hasSelectedTasks() {
                return this.tasks.filter(task => task.selected).length;
            },
        },
        watch: {
            tasksBeingLoaded(val, oldVal) {
                if (!val && oldVal) {
                    this.$store.dispatch('storage_senior_dashboard/reCalculateRawMaterialsStatus');
                    this.clearSelection();
                }
            },
            restsBeingLoaded(val, oldVal) {
                if (!val && oldVal) {
                    this.$store.dispatch('storage_senior_dashboard/reCalculateRawMaterialsStatus');
                }
            },
            storage(val) {
                this.$store.commit('storage_senior_dashboard/set_selected_tasks', []);
            },
            actualShift(val) {
                this.fetchTasks();
                this.$store.commit('storage_senior_dashboard/set_selected_tasks', []);
                this.selectedIndex = -1;
            },
            minimized(val) {
                this.$emit('toggleMinimized', val);
            },
            selectedIndexes(val) {
                let tasks  = [];

                Object.keys(val).map(k => {
                    tasks.push(this.tasks[k]);
                });

                this.$store.commit('storage_senior_dashboard/set_selected_tasks', tasks);
            },
        },
        methods: {
            moment,
            touchMove(val) {
                if (val) {
                    clearTimeout(this.scrollingTimeout);
                    this.scrolling = true;
                } else {
                    this.scrollingTimeout = setTimeout(() => {
                        this.scrolling = false;
                    }, this.touchLength);
                }
            },
            clearSelection() {
                this.status = null;
                this.batchSelection = false;
                this.selectedIndexes = {};
            },
            selectTask(event, task, index, batch = null) {
                if (this.scrolling || this.isLoading) {
                    return;
                }

                if (batch !== null && this.taskSelectable(task, index)) {
                    this.batchSelection = true;
                }

                if (this.batchSelection && !this.taskSelectable(task, index)) {
                    return;
                }

                if (!this.batchSelection) {
                    this.selectedIndexes = {};
                }

                if (!!this.selectedIndexes[index]) {
                    this.$delete(this.selectedIndexes, index);
                } else {
                    this.$set(this.selectedIndexes, index, true);
                }

                if (Object.keys(this.selectedIndexes).length === 0) {
                    this.batchSelection = false;
                }

                this.$forceUpdate();
            },
            taskSelectable(task, index) {
                if (task.productionActs.length !== 0) {
                    return false;
                }

                if (Object.keys(this.selectedIndexes).length === 0) {
                    return true;
                }

                let firstTask = this.tasks[Object.keys(this.selectedIndexes)[0]];
                let storageCorrect = false;

                if (!!task.parent) {
                    if (!!firstTask.parent) {
                        storageCorrect = task.parent.storage.id === firstTask.parent.storage.id;
                    } else {
                        storageCorrect = false;
                    }
                } else if (!(!!firstTask.parent)) {
                    storageCorrect = true;
                }

                return task.nomenclature.id === firstTask.nomenclature.id
                    && task.operation.id === firstTask.operation.id
                    && storageCorrect
                    && firstTask.fromPreviousShift === task.fromPreviousShift;
            },
            taskDelayed(task) {
                return (
                    (!this.actExists(task) && moment(task.production_start_at).format('X') < moment().format('X'))
                    ||
                    (!this.taskFullyProduced(task) && moment(task.production_end_at).format('X') < moment().format('X'))
                );
            },
            statusCode(task) {
                if (this.actExists(task) && !this.taskFullyProduced(task)) {
                    return 'in_progress';
                }
                if (this.taskFullyProduced(task)) {
                    return 'finished';
                }
                if (this.taskDelayed(task)) {
                    return 'delayed';
                }

                return 'next';
            },
            taskPassesFilter(task, index) {
                let passesStatus = this.statusCode(task) === this.status;

                if (this.status && !passesStatus) {
                    return false;
                }

                if (this.filters.plans.length < 1) {
                    return true;
                }

                return Object.keys(task.plans).some(id => this.filters.plans.includes(id));
            },
            taskStatus(task) {

                if (this.taskFullyProduced(task)) {
                    return 'завершена';
                }

                if (this.actExists(task)) {
                    return 'в работе';
                }

                return 'не начата';
            },
            taskClass(task, index) {
                let selected = !!this.selectedIndexes[index];

                return {
                    'alert-info': selected,
                    'alert-success': !selected && this.taskFullyProduced(task),
                    'alert-warning': !selected && !this.taskFullyProduced(task) && this.actExists(task),
                    'alert-danger': !selected && !this.actExists(task) && this.taskDelayed(task),
                    'disabled': this.batchSelection && !this.taskSelectable(task, index)
                };
            },
            actExists(task) {
                return task.productionActs.length > 0;
            },
            taskFullyProduced(task) {
                if (!this.actExists(task)) {
                    return false;
                }

                if (task.productionActs[0].assume_finished) {
                    return true;
                }

                return this.producedCount(task) >= this.requiredCount(task) && this.fullyTransferred(task);
            },
            hasNonAutoPlan(task) {
                let result = false;
                Object.keys(task.plans).map(k => {
                    if (
                        this.plans[k].reason.name !== 'auto'
                    ) {
                        result = true;
                    }
                });

                return result;
            },
            planReasonString(task) {
                let names = [];
                Object.keys(task.plans).map(k => {
                    if (
                        this.plans[k].reason.name !== 'auto'
                    ) {
                        names.push(this.plans[k].reason.comment);
                    }
                });

                if (!names.length) {
                    return '';
                }

                return names.join(', ');
            },
            targetProductString(task) {
                if (Object.keys(task.plans).length === 1) {
                    return this.plans[Object.keys(task.plans)[0]].nomenclature.name;
                }

                return 'несколько блюд';
            },
            needsTransfer(task) {

                if (!!task.parent && !!task.parent.storage && task.parent.storage.id !== this.storage.id) {
                    return true;
                }

                if (this.storage.packing) {
                    return true;
                }

                return false;
            },
            fullyTransferred(task) {
                if (!this.needsTransfer(task)) {
                    return true;
                }

                return this.transferredCount(task) >= this.producedCount(task);
            },
            requiredCount(task) {
                return parseFloat(parseFloat(task.count).toFixed(3));
            },
            producedCount(task) {
                return parseFloat(sumBy(task.productionActs, a => sumBy(a.items, i => {
                    if (this.isProductionActItemCountable(i)) {
                        return parseFloat(i.count)
                    }

                    return 0;
                })).toFixed(3));
            },
            isProductionActItemCountable(item) {
                if (!!this.shiftClosingAct && !!this.shiftClosingAct.blocked_at) {
                    return moment(item.created_at).format('X') <= moment(this.shiftClosingAct.blocked_at).format('X');
                }

                return true;
            },
            transferredCount(task) {
                return parseFloat(sumBy(task.productionActs, pa => sumBy(pa.materialTransferActs, a => a.canceled ? 0 : sumBy(a.items, i => {
                    if (i.nomenclatureLot.nomenclature_id === task.nomenclature.id) {
                        return parseFloat(i.count);
                    }

                    return 0;
                }))).toFixed(3));
            },
            startTime(task) {
                return moment(task.production_start_at).format('HH:mm');
            },
            startTimeClass(task) {
                let now = moment().format('X');
                let start_at = moment(task.production_start_at).format('X');

                return {
                    'alert-danger': !this.actExists(task) && start_at < now,
                    'alert-success': this.actExists(task)
                }
            },
            endTime(task) {
                return moment(task.production_end_at).format('HH:mm')
            },
            endTimeClass(task) {
                let now = moment().format('X');
                let end_at = moment(task.production_end_at).format('X');

                return {
                    'alert-danger': !this.taskFullyProduced(task) && end_at < now,
                }
            },
            hasMissingRawMaterials(task) {
                let result = false;
                task.stuff.map(s => {
                    if (parseFloat(s.count) > s.on_stock) {
                        result = true;
                    }
                });

                return result;
            },
            togglePlanFilter(id, value) {
                this.$store.commit('storage_senior_dashboard/toggle_plan_filter', {id: id, value: value})
            },
            clearPlanFilter() {
                this.$store.commit('storage_senior_dashboard/set_plan_filter', []);
            },
            produceSelected() {
                const WITH = [
                    'materialTransferActs.storageFrom',
                    'materialTransferActs.items.nomenclatureLot.nomenclature.measureUnit',
                ];
                const createProductionAct = (task) => {
                    let stuff = [];
                    task.stuff.forEach((item) => {
                        stuff.push({
                            nomenclature: {id: item.nomenclature.id},
                            count: item.count
                        });
                    });

                    const items = [{
                        plan_count: task.count,
                        count: 0,
                        box: {
                            name: BarcodeRepository.boxPrefixes[1] + Math.floor(Math.random() * (90000) + 10000).toString(),
                        },
                        responsibleUsers: [{...this.$auth.user(), actual_shift_id: this.actualShift.id}],
                    }];

                    return this.$http.post(
                        PRODUCTION_ACTS_ENDPOINT,
                        {
                            nomenclature: {id: task.nomenclature.id},
                            use_proportional_raw_materials: 0,
                            start_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                            tasks: [{id: task.ids[0]}],
                            shift: {id: this.actualShift.shift.id},
                            storage: {id: this.storage.id},
                            rawMaterials: stuff,
                            items: items,

                            with: WITH,
                        },
                        {
                            params: {
                                without_loading: true
                            },
                        }
                    );
                }

                const produceProductionAct = (act) => {
                    const items = act.items.map((item) => {
                        return {
                            ...item,
                            count: item.plan_count,
                        };
                    });

                    return this.$http.put(
                        PRODUCTION_ACTS_ENDPOINT + `/${act.id}/produce`,
                        {
                            items: items,
                            end_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                            assume_finished: 1,

                            with: WITH,
                        },
                        {
                            params: {
                                without_loading: true
                            },
                        }
                    );
                }

                const createPromises = [];
                for (const task of this.tasks) {
                    if (task.selected !== true || task.productionActs.length) {
                        continue;
                    }

                    createPromises.push(createProductionAct(task));
                }

                this.isLoading = true;
                Promise.all(createPromises).then((responses) => {
                    const producePromises = [];
                    for (const response of responses) {
                        producePromises.push(produceProductionAct(response.data.data));
                    }

                    Promise.all(producePromises).then(() => {
                        this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
                        this.$store.dispatch('storage_senior_dashboard/loadTasks');
                        this.isLoading = false;
                    });
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .task {
        &-multiple {
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                display: block;
                z-index: -1;
                border-radius: .25rem;
            }
            &:before {
                top: .25rem;
                left: -.25rem;
                background: black;
                opacity: .25;
            }
        }

        position: relative;
        user-select: none;
        cursor: pointer;
        display: flex;
        flex-direction: row;

        &-title {
            font-weight: bold;
            font-size: 18px;
        }
        &-left {
            width: 75%;
        }
        &-right {
            min-width: 25%;
            height: 100%;
            border-left: 1px dashed gray;
        }
        &-amount {
            font-weight: bold;
            white-space: nowrap;
            word-break: keep-all;
        }
        &-info {
            display: flex;
            flex-direction: row;
        }
        &-timing {
            font-weight: bold;

        }
    }
    .dashboard-flex {
        display: flex; justify-content: center; align-items: center; align-content: center; height: 100%
    }
    .border-bottom-dashed {
        border-bottom: 1px dashed gray;
    }
</style>
