<template>
    <div class="card mb-0">
        <div class="card-header">Завершить</div>
        <div class="card-body" style="padding-bottom: 0 !important;">
            <div class="row">
                <div class="col-6">
                    <b-form-group description="Начали из расчета на">
                        <b-input-group :append="measureUnit">
                            <input :value="item.plan_count"
                                   disabled
                                   type="number"
                                   style="height: unset; font-size: 48px;"
                                   class="form-control text-center">
                        </b-input-group>
                    </b-form-group>
                </div>
                <div class="col-6">
                    <b-form-group description="По факту получилось">
                        <b-input-group :append="measureUnit">
                            <b-form-input
                                v-model.number="item.count"
                                :disabled="isDisabled()"
                                debounce="500"
                                type="number"
                                min="0"
                                step="0.001"
                                class="text-center"
                                style="height: unset; font-size: 48px;"
                            />
                        </b-input-group>
                    </b-form-group>
                </div>
            </div>

            <b-row v-show="tasks[0].operation.has_shock_chilling">
                <b-col cols="6">
                    <b-form-group>
                        <template #label>
                            Температура после шоковой камеры <sup><b-icon-asterisk variant="danger" scale="0.5"/></sup>
                        </template>
                        <b-input-group append="℃">
                            <input
                                v-model.number="items[index].temperature_after_shock"
                                max="10"
                                min="0"
                                step="1"
                                type="number"
                                class="form-control"
                            />
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
        </div>
        <div class="card-body" style="padding-top: 0 !important; padding-bottom: 0 !important;">
            <b-form-group description="Назначить ответственного">
                <b-card no-body class="text-left mb-0">
                    <b-card-header v-show="hasResponsibleUsers" class="responsible-users-header dropdown-toggle" v-b-toggle.responsible-users-collapse>
                        {{ responsibleUserNames }}
                    </b-card-header>
                    <b-collapse id="responsible-users-collapse" v-model="responsibleUsersCollapsed">
                        <b-card-body>
                            <div v-if="workingUsersLoading" class="text-center">
                                <i class="fas fa-spinner fa-spin" />
                            </div>
                            <b-form-checkbox-group v-else v-model="items[index].responsibleUsers">
                                <div class="container">
                                    <div class="row row-cols-2">
                                        <div v-for="user in workingUsers" :key="user.id" class="col responsible-user-column mt-1 mb-1">
                                            <b-form-checkbox :disabled="isDisabled() || isResponsibleUserDisabled(user)" name="responsible-user" :value="user">
                                                {{ user.fullname ? user.fullname : user.username }}
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </b-form-checkbox-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-form-group>
            <b-row>
                <b-col cols="12">
                    <b-form-checkbox v-model="useProportionalRawMaterials">Указать перерасход сырья?</b-form-checkbox>
                </b-col>
            </b-row>
            <b-row v-if="useProportionalRawMaterials">
                <b-col cols="12">
                    <b-table
                        :items="proportionalRawMaterialsItems"
                        :fields="proportionalRawMaterialsFields"
                        bordered
                        hover
                        head-variant="dark"
                    >
                        <template #head(count)>
                            Количество на {{ item.count }} {{ measureUnit }}
                        </template>

                        <template #cell(count)="row">
                            <b-input-group :append="measureUnit">
                                <b-form-input v-model.number="row.item.count" type="number" min="0" step="0.01" />
                            </b-input-group>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </div>
        <div class="card-footer text-center">
            <button @click="save()" :disabled="isDisabled() || !hasResponsibleUsers" class="btn btn-lg btn-success">
                <i v-if="isSavingProducedCount()" class="fas fa-spinner fa-spin"></i>
                <span>Сохранить</span>
            </button>
        </div>
    </div>
</template>

<script>
    import {productionActMixin} from '@mixins';
    import {cloneDeep} from 'lodash';

    export default {
        name: 'FinishModal',
        mixins: [productionActMixin],
        props: {
            index: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                item: {},
                items: [],
                assume_finished: false,
                responsibleUsersCollapsed: false,
                useProportionalRawMaterials: false,
                proportionalRawMaterialsItems: [],
                proportionalRawMaterialsFields: [
                    {
                        key: 'nomenclature.name',
                        label: 'Наименование номенклатуры',
                    },
                    {
                        key: 'stuff_count',
                        label: 'Количество',
                        formatter(value, key, item) {
                            return `${value} ${item.nomenclature.measure_unit_name}`;
                        }
                    },
                    {
                        key: 'on_stock_count',
                        label: 'На остатках',
                        formatter(value, key, item) {
                            return `${value} ${item.nomenclature.measure_unit_name}`;
                        }
                    },
                    {
                        key: 'count',
                    }
                ],
            };
        },
        computed: {
            responsibleUserNames() {
                return this.items[this.index].responsibleUsers.map(
                    user => user.fullname ? user.fullname : user.username
                ).join(', ')
            },
            hasResponsibleUsers() {
                return this.items[this.index].responsibleUsers.length > 0
            },
            requiredCount() {
                return this.tasks.reduce((accumulator, item) => accumulator + item.count, 0);
            }
        },
        watch: {
            'item.count': {
                handler: function(value) {
                    this.proportionalRawMaterialsItems.map((item) => {
                        item.count = parseFloat((item.stuff_count * (value / this.requiredCount)).toFixed(3));
                    });
                },
            },
        },
        methods: {
            save() {
                if (this.useProportionalRawMaterials === true) {
                    this.item.proportional_raw_materials = this.proportionalRawMaterialsItems;
                } else {
                    this.item.proportional_raw_materials = [];
                }
                this.items[this.index] = this.item;

                this.updateProductionAct(this.items, this.assume_finished);
            },
            isDisabled() {
                return this.isItemDisabled(this.items[this.index]) || this.isSavingProducedCount();
            },
            isResponsibleUserDisabled(user) {
                return user.actual_shift_id && user.actual_shift_id !== this.relevantShift.id
            },
            resetResponsibleUsers() {
                const responsibleUsers = []
                for (let user of this.items[this.index].responsibleUsers) {
                    if (!user.actual_shift_id) {
                        user.actual_shift_id = this.relevantShift.id
                    }
                    const foundUser = this.workingUsers.find(
                        workingUser => workingUser.id === user.id
                    )
                    if (foundUser) {
                        foundUser.actual_shift_id = user.actual_shift_id
                        responsibleUsers.push(foundUser)
                    } else {
                        this.workingUsers.push(user)
                        this.workingUsers.sort(
                            (a, b) => (a.fullname ? a.fullname : a.username).localeCompare(b.fullname ? b.fullname : b.username)
                        )
                        responsibleUsers.push(user)
                    }
                }
                this.items[this.index].responsibleUsers = responsibleUsers
            }
        },
        created() {
            this.items = cloneDeep(this.tasks[0].productionActs[0].items);

            this.item = this.items[this.index];

            this.proportionalRawMaterialsItems = this.tasks[0].stuff.map((item) => {
                return {
                    nomenclature: {
                        id: item.nomenclature.id,
                        name: item.nomenclature.name,
                        measure_unit_name: item.nomenclature.measure_unit.name,
                    },
                    count: 0,
                    on_stock_count: item.on_stock,
                    stuff_count: item.count,
                };
            });

            this.assume_finished = this.tasks[0].productionActs[0].assume_finished;
            if (!this.hasResponsibleUsers) {
                this.responsibleUsersCollapsed = true
            }

            this.loadRelevantShift()
                .then(this.loadWorkingUsers)
                .then(this.resetResponsibleUsers)
        }
    }
</script>

<style>
    .responsible-users-header.collapsed {
        border-bottom: 0;
        border-radius: calc(0.25rem - 1px);
    }

    .responsible-user-column {
        flex: 0 0 50%;
        max-width: 50%;
    }
</style>
