<template>
    <div class="card mb-0">
        <div class="card-header">Считать задание выполненным</div>
        <div class="card-body" style="font-size: 18px;">
            <template v-if="!assumeFinished">
                <template v-if="inProgressCount > 0">
                    <p><b>{{inProgressCount.toFixed(3)}} {{measureUnit}}</b> всё еще находятся в работе</p>
                    <p>Проверьте порции и укажите получившееся количество, нажав
                        <button class="btn btn-sm btn-success" disabled>закончить</button>
                        напротив порции</p>
                    <p>Либо укажите <b>0 {{measureUnit}}</b>, нажав
                        <button class="btn btn-sm" disabled><i class="fas fa-edit"></i> изменить</button>
                        напротив порции
                    </p>
                </template>
                <template v-else>
                    <p>Задание выполнено не полностью! Осталось приготовить <b>{{leftToProduce}} {{measureUnit}}</b></p>
                    <p>Если Вы считаете, что уже приготовленных <b>{{producedCount}} {{measureUnit}}</b> из <b>{{requiredCount}} {{measureUnit}}</b> достаточно</p>
                    <p>И экономика не рухнет, если вы не доготовите <b>{{leftToProduce}} {{measureUnit}}</b></p>
                    <p>Тогда жмите <button class="btn btn-sm btn-success" disabled>Считать выполненным</button></p>
                </template>
            </template>
            <template v-else>
                <p>Перестать считать задание выполненным?</p>
                <p>Вам придется доготовить <b>{{leftToProduce}} {{measureUnit}}</b></p>
            </template>
        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-6 text-left">
                    <button class="btn btn-lg btn-danger" @click="$emit('close')">Отмена</button>
                </div>
                <div class="col-6 text-right">
                    <template v-if="inProgressCount <= 0">
                        <button @click="save()"
                                :disabled="isActDisabled() || isSavingProducedCount()"
                                class="btn btn-lg btn-success">
                            <i v-if="isSavingProducedCount()" class="fas fa-spinner fa-spin"></i>
                            <span v-if="!assumeFinished">Считать выполненным</span>
                            <span v-else>Считать <b style="text-decoration: underline;">не</b> выполенным</span>
                        </button>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {productionActMixin} from "@mixins";
    import {sumBy} from 'lodash';

    export default {
        name: "FinishAct",
        mixins: [productionActMixin],
        computed: {
            inProgressCount() {
                return sumBy(this.tasks[0].productionActs[0].items, i => {
                    if (!i.count) {
                        return i.plan_count;
                    }

                    return 0;
                });
            },
            assumeFinished() {
                return this.tasks[0].productionActs[0].assume_finished;
            }
        },
        data() {
            return {

            };
        },
        methods: {
            save() {
                let items = this.tasks[0].productionActs[0].items;
                this.updateProductionAct(items, !this.assumeFinished);
            }
        },
    }
</script>

<style scoped>

</style>
