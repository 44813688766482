<template>
    <table class="table table-sm table-borderless">
        <tbody>
        <tr v-for="(item, index) in items">
            <td>
                <div class="form-group">
                    <span style="white-space: nowrap">
                        {{item.actualShift.name}}<br>
                        {{moment(item.created_at).format('DD.MM.YY в HH:mm')}}
                    </span>
                </div>
            </td>
            <td>
                <button @click="print(item)" class="btn">
                    <i v-if="isLoadingTtk(item)" class="fas fa-spinner fa-spin"></i>
                    <i v-else class="fas fa-print"></i>
                    <span> ТТК</span>
                </button>
            </td>
            <td>
                <div class="form-group">
                    <b-input-group :append="measureUnit">
                        <input disabled :value="item.count ? item.count : item.plan_count" type="number" class="form-control text-right" step="0.001">
                    </b-input-group>
                </div>
            </td>
            <td>
                <button class="btn" @click="edit(item, index)">
                    <template v-if="isItemDisabled(item)">
                        <i class="fas fa-eye"></i> смотреть
                    </template>
                    <template v-else>
                        <i class="fas fa-edit"></i> изменить
                    </template>
                </button>
            </td>
            <td>
                <button v-if="isInProgress(item)" :disabled="isItemDisabled(item)" @click="finish(item, index)" class="btn btn-success">закончить</button>
            </td>
            <td>
                <div v-if="isInProgress(item)" class="mt-2" style="white-space: nowrap"><i class="fas fa-clock"></i> в работе</div>
                <div v-else class="mt-2"><i class="fas fa-check"></i> готово</div>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    import moment from 'moment';
    import {productionActMixin} from '@mixins';
    import EditItem from "./EditItem";
    import FinishItem from "./FinishItem";
    import {PRODUCTION_TASKS_ENDPOINT} from "../../../../utils/endpoints";
    import Downloader from "../../../../utils/Downloader";

    export default {
        name: "ItemsList",
        mixins: [productionActMixin],
        computed: {
            items() {
                let items = [];
                if (this.tasks[0].productionActs.length) {
                    return this.tasks[0].productionActs[0].items;
                }

                return items;
            },
        },
        methods: {
            moment,
            isInProgress(item) {
                return item.count <= 0;
            },
            edit(item, index) {
                this.$modal.show(EditItem, {index}, this.productionActParams.modal);
            },
            finish(item, index) {
                this.$modal.show(FinishItem, {index}, this.productionActParams.modal);
            },
            isLoadingTtk(item) {
                return !!item.ttkDownloading;
            },
            print(item) {
                let count = item.plan_count;
                let taskId = this.tasks[0].ids[0];

                if (count <= 0) {
                    this.$toast.warning('Нельзя печатать нулевое кол-во');
                    return;
                }

                item.ttkDownloading = true;
                this.$forceUpdate();

                this.$http.get(PRODUCTION_TASKS_ENDPOINT + `/ttk/print`, {
                    responseType: 'arraybuffer',
                    params: {
                        task_id: taskId,
                        count: count,
                        without_loading: true
                    }
                }).then(response => {
                    Downloader.download(response.data, 'application/pdf', `ТТК ${taskId}.pdf`);
                }).catch(response => {
                    this.$toast.error(resposne.data.message);
                }).finally(() => {
                    item.ttkDownloading = false;
                    this.$forceUpdate();
                });
            }
        }
    }
</script>

<style scoped>

</style>
