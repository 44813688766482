<template>
    <div class="card mb-0">
        <div class="card-header">История передач</div>
        <div class="card-body" style="padding: 0 !important">
            <table class="table table-striped table-bordered mb-0">
                <thead class="thead-dark">
                <tr>
                    <th>#</th>
                    <th class="text-right">Кол-во</th>
                    <th class="text-right">Дата</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="act in acts">
                    <td>
                        <i class="fas fa-circle" :style="{'color': act.canceled ? 'red' : (act.received ? 'green' : 'orange')}"></i>
                        {{act.id}}
                    </td>
                    <td class="text-right">{{sumBy(act.items, i => i.count).toFixed(3)}} {{measureUnit}}</td>
                    <td class="text-right">{{act.date}}</td>
                    <td class="text-center">
                        <button @click="showPrintModal(act)" class="btn"><i class="fas fa-print"></i> Печать наклеек</button>
                    </td>
                    <td class="text-right">
                        <router-link :to="{name: 'MaterialTransferActUpdate', params: {id: act.id}}" class="btn btn-default">К акту <i class="fas fa-angle-double-right"></i></router-link>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import MaterialTransferPrintModal from "../../../materialtransferact/MaterialTransferPrintModal";
    import {sumBy} from 'lodash';

    export default {
        name: "TransferHistory",
        props: {
            acts: {
                type: Array,
                required: true
            },
            measureUnit: {
                type: String,
                required: true,
            }
        },
        methods: {
            sumBy,
            showPrintModal(act) {
                this.$modal.show(MaterialTransferPrintModal, {
                    act: act,
                    successCallback: () => {}
                }, {
                    adaptive: true,
                    height: 'auto',
                    width: 900,
                    scrollable: true,
                });
            }
        }
    }
</script>

<style scoped>

</style>
