<template>
    <div class="card mb-0">
        <div class="card-header">Изменить порцию</div>
        <div class="card-body" style="padding-bottom: 0 !important;">
            <div class="row">
                <div class="col-12">
                    <b-form-group description="Начали готовить из расчета на">
                        <b-input-group :append="measureUnit">
                            <input v-model="items[index].plan_count"
                                   type="number"
                                   :disabled="isDisabled()"
                                   style="height: unset; font-size: 48px;"
                                   class="form-control text-center"
                                   step="0.001">
                        </b-input-group>
                    </b-form-group>
                </div>
            </div>
            <div v-if="oldCount > 0" class="row">
                <div class="col-12">
                    <b-form-group description="По факту получилось">
                        <b-input-group :append="measureUnit">
                            <input v-model.number="items[index].count"
                                   :disabled="isDisabled()"
                                   type="number"
                                   style="height: unset; font-size: 48px;"
                                   class="form-control text-center"
                                   min="0.001"
                                   step="0.001">
                        </b-input-group>
                    </b-form-group>
                </div>
            </div>
        </div>
        <div class="card-body" style="padding-top: 0 !important; padding-bottom: 0 !important;">
            <b-form-group description="Назначить ответственного">
                <b-card no-body class="text-left mb-0">
                    <b-card-header v-show="hasResponsibleUsers" class="responsible-users-header dropdown-toggle" v-b-toggle.responsible-users-collapse>
                        {{ responsibleUserNames }}
                    </b-card-header>
                    <b-collapse id="responsible-users-collapse" v-model="responsibleUsersCollapsed">
                        <b-card-body>
                            <div v-if="workingUsersLoading" class="text-center">
                                <i class="fas fa-spinner fa-spin" />
                            </div>
                            <b-form-checkbox-group v-else v-model="items[index].responsibleUsers">
                                <div class="container">
                                    <div class="row row-cols-2">
                                        <div v-for="user in workingUsers" :key="user.id" class="col responsible-user-column mt-1 mb-1">
                                            <b-form-checkbox :disabled="isDisabled() || isResponsibleUserDisabled(user)" name="responsible-user" :value="user">
                                                {{ user.fullname ? user.fullname : user.username }}
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </b-form-checkbox-group>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </b-form-group>
        </div>
        <div class="card-footer text-center">
            <button @click="save()" :disabled="isDisabled() || !hasResponsibleUsers" class="btn btn-lg btn-success">
                <i v-if="isSavingProducedCount()" class="fas fa-spinner fa-spin"></i>
                <span>Сохранить</span>
            </button>
        </div>
    </div>
</template>

<script>
    import {cloneDeep} from 'lodash';
    import {productionActMixin} from '@mixins';

    export default {
        name: "EditModal",
        mixins: [productionActMixin],
        props: {
            index: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                items: [],
                assume_finished: false,
                responsibleUsersCollapsed: false,
                oldCount: 0,
            }
        },
        computed: {
            responsibleUserNames() {
                return this.items[this.index].responsibleUsers.map(
                    user => user.fullname ? user.fullname : user.username
                ).join(', ')
            },
            hasResponsibleUsers() {
                return this.items[this.index].responsibleUsers.length > 0
            }
        },
        methods: {
            save() {
                this.updateProductionAct(this.items, this.assume_finished);
            },
            isDisabled() {
                return this.isItemDisabled(this.items[this.index]) || this.isSavingProducedCount();
            },
            isResponsibleUserDisabled(user) {
                return user.actual_shift_id && user.actual_shift_id !== this.relevantShift.id
            },
            resetResponsibleUsers() {
                const responsibleUsers = []
                for (let user of this.items[this.index].responsibleUsers) {
                    if (!user.actual_shift_id) {
                        user.actual_shift_id = this.relevantShift.id
                    }
                    const foundUser = this.workingUsers.find(
                        workingUser => workingUser.id === user.id
                    )
                    if (foundUser) {
                        foundUser.actual_shift_id = user.actual_shift_id
                        responsibleUsers.push(foundUser)
                    } else {
                        this.workingUsers.push(user)
                        this.workingUsers.sort(
                            (a, b) => (a.fullname ? a.fullname : a.username).localeCompare(b.fullname ? b.fullname : b.username)
                        )
                        responsibleUsers.push(user)
                    }
                }
                this.items[this.index].responsibleUsers = responsibleUsers
            }
        },
        created() {
            this.items = cloneDeep(this.tasks[0].productionActs[0].items);
            this.assume_finished = this.tasks[0].productionActs[0].assume_finished;
            this.oldCount = this.items[this.index].count; // нужен, чтобы второе поле не скрывалось, когда редактируют count
            if (!this.hasResponsibleUsers) {
                this.responsibleUsersCollapsed = true
            }

            this.loadRelevantShift()
                .then(this.loadWorkingUsers)
                .then(this.resetResponsibleUsers)
        }
    }
</script>

<style>
    .responsible-users-header.collapsed {
        border-bottom: 0;
        border-radius: calc(0.25rem - 1px);
    }

    .responsible-user-column {
        flex: 0 0 50%;
        max-width: 50%;
    }
</style>
