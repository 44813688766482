<template>
    <div class="card mb-0">
        <div class="card-header">Начать</div>
        <div class="card-body" style="padding-bottom: 0 !important;">
            <div class="row">
                <div class="col-5">
                    <b-form-group>
                        <b-input-group :append="measureUnit">
                            <input :value="leftToProduce > 0 ? leftToProduce : 0"
                                   disabled
                                   type="number"
                                   style="height: unset; font-size: 48px;"
                                   class="form-control text-center">
                        </b-input-group>
                    </b-form-group>
                </div>
                <div class="col-2 text-center">
                    <button :disabled="isDisabled()" @click="count = (leftToProduce > 0 ? leftToProduce : 0)" class="btn btn-lg mt-4"><i class="fas fa-arrow-right"></i></button>
                </div>
                <div class="col-5">
                    <b-form-group>
                        <b-input-group :append="measureUnit">
                            <input v-model.number="count"
                                   :disabled="isDisabled()"
                                   type="number"
                                   style="height: unset; font-size: 48px;"
                                   class="form-control text-center"
                                   step="0.001">
                        </b-input-group>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <p>
                    Количество, которое <u>осталось</u> приготовить
                    </p>
                </div>
                <div class="col-2"></div>
                <div class="col-5">
                    <p>
                    Здесь указывается количество, которые вы <br>
                    <u>Начинаете готовить прямо сейчас</u>
                    </p>
                </div>
            </div>
        </div>
        <div class="card-body" style="padding-top: 0 !important; padding-bottom: 0 !important;">
            <b-form-group description="Назначить ответственного">
                <b-card no-body class="text-left mb-0">
                    <b-card-body>
                        <div v-if="workingUsersLoading" class="text-center">
                            <i class="fas fa-spinner fa-spin" />
                        </div>
                        <b-form-checkbox-group v-else v-model="responsibleUsers" @change="onResponsibleUsersChange">
                            <div class="container">
                                <div class="row row-cols-2">
                                    <div v-for="user in workingUsers" :key="user.id" class="col responsible-user-column mt-1 mb-1">
                                        <b-form-checkbox name="responsible-user" :value="user">
                                            {{ user.fullname ? user.fullname : user.username }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                        </b-form-checkbox-group>
                    </b-card-body>
                </b-card>
            </b-form-group>
        </div>
        <div class="card-footer text-center">
            <button :disabled="count <= 0 || isDisabled() || !hasResponsibleUsers" @click="save()" class="btn btn-lg btn-success">
                <i v-if="isSavingProducedCount() || isCreatingProductionAct()" class="fas fa-spinner fa-spin"></i>
                <span>Сохранить</span>
            </button>
        </div>
    </div>
</template>

<script>
    import {productionActMixin} from '@mixins';
    import {cloneDeep} from 'lodash';
    import BarcodeRepository from "../../../../utils/BarcodeRepository";

    export default {
        name: "NewItem",
        mixins: [productionActMixin],
        data() {
            return {
                items: [],
                assume_finished: false,
                responsibleUsersModified: false,
                responsibleUsers: [],
                count: 0,
            };
        },
        computed: {
            hasResponsibleUsers() {
                return this.responsibleUsers.length > 0
            }
        },
        methods: {
            save() {
                let doCreate = this.tasks[0].productionActs.length === 0;
                let item = {
                    plan_count: this.count,
                    count: 0,
                    box: {
                        name: BarcodeRepository.boxPrefixes[ 1 ] + Math.floor( Math.random() * (90000) + 10000 ).toString()
                    },
                    responsibleUsers: this.responsibleUsers
                };

                if (doCreate) {
                    this.createProductionAct(item);
                } else {
                    let items = cloneDeep(this.items);
                    items.push(item);
                    this.updateProductionAct(items, this.assume_finished);
                }
            },
            isDisabled() {
                return this.isSavingProducedCount() || this.isCreatingProductionAct() || this.isActDisabled()
            },
            onResponsibleUsersChange() {
                if (!this.responsibleUsersModified) {
                    this.$nextTick(() => {
                        const currentUser = this.$auth.user()
                        const foundIndex = this.responsibleUsers.findIndex(
                            responsibleUser => responsibleUser.id === currentUser.id
                        )
                        if (foundIndex !== -1) {
                            this.responsibleUsers.splice(foundIndex, 1);
                        }
                        this.responsibleUsersModified = true
                    });
                }
            },
            resetResponsibleUsers() {
                const currentUser = this.$auth.user()
                currentUser.actual_shift_id = this.relevantShift.id
                this.responsibleUsers.push(currentUser)
            }
        },
        created() {
            if (this.tasks[0].productionActs.length) {
                this.items = cloneDeep(this.tasks[0].productionActs[0].items);
                this.assume_finished = this.tasks[0].productionActs[0].assume_finished;
            }

            this.count = 0;

            this.loadRelevantShift()
                .then(this.loadWorkingUsers)
                .then(this.resetResponsibleUsers)
        }
    }
</script>

<style>
    .responsible-user-column {
        flex: 0 0 50%;
        max-width: 50%;
    }
</style>
