<template>
    <div class="card task-wrapper">
        <div>
            <div class="card-header">
                <div class="row">
                    <div class="col-8">
                        <b>{{ tasks[0].nomenclature.name }}</b>
                    </div>
                    <div class="col-3 text-right">
                        <b>{{ requiredCount.toFixed(3) }}&nbsp;{{ measureUnit }}</b>
                    </div>
                    <div class="col-1">
                        <button  @click="closeTaskWindow" class="close" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body"
                 style="position: sticky; top: 113px; height: calc(100vh - 195px); overflow-y: auto; overflow-x: hidden">
                <div class="row mb-2">
                    <div class="col-3">
                        <button
                            :disabled="isDisabled()"
                            @click="newItem()"
                            class="btn btn-lg btn-outline-success"
                        >
                            <template v-if="tasks[0].productionActs.length === 0">
                                <i v-if="isCreatingProductionAct()" class="fas fa-spinner fa-spin"></i>
                                <i v-else class="fas fa-play"></i>
                            </template>
                            <template v-else>
                                <i v-if="isSavingProducedCount()" class="fas fa-spinner fa-spin"></i>
                                <i v-else class="fas fa-plus"></i>
                            </template>
                            <span> Начать</span>
                        </button>
                    </div>

                    <div class="col-9 text-right">
                        <button
                            v-if="!!status && (status !== 'finished' || tasks[0].productionActs[0].assume_finished)"
                            :disabled="isDisabled()"
                            @click="assumeFinished()"
                            :class="{'btn-outline-danger': !tasks[0].productionActs[0].assume_finished, 'btn-outline-success': tasks[0].productionActs[0].assume_finished}"
                            class="btn btn-lg mr-2"
                        >
                            <template v-if="tasks[0].productionActs[0].assume_finished" class="col-1 p-0 pl-1 pt-1">
                                <i class="fas fa-check"></i>
                            </template>
                            Считать выполненным
                        </button>

                        <b-dropdown v-if="tasks[0] && tasks[0].productionActs[0]" variant="outline-info" size="lg" class="mr-2">
                            <template #button-content>
                                <b-icon-printer-fill/>
                            </template>
                            <b-dropdown-item
                                v-if="tasks[0] && isVegetableStorage"
                                v-b-modal.print-modal
                                :disabled="isDisabled()"
                            >
                                Этикетка
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="tasks[0] && tasks[0].productionActs[0]"
                                @click="getMaterialTransferInvoice()"
                            >
                                Накладная на Mini K (PDF)
                            </b-dropdown-item>
                        </b-dropdown>

                        <button
                            v-if="tasks[0].productionActs.length !== 0 && accessForCreateQualityControlAct()"
                            v-b-modal.quality-control-act-form
                            :disabled="isDisabled()"
                            class="btn btn-lg btn-outline-warning"
                        >
                            Качество
                        </button>
                    </div>
                </div>
                <div v-if="tasks[0].fromPreviousShift" class="row mb-2">
                    <div class="col-12">
                        <div class="alert alert-info mb-0"><b>с прошлой смены</b></div>
                    </div>
                </div>
                <div v-if="tasks[0].fromBeforeLastShift" class="row mb-2">
                    <div class="col-12">
                        <div class="alert alert-danger mb-0"><b>с позапрошлой смены</b></div>
                    </div>
                </div>
                <div v-if="!status" class="row">
                    <div class="col-12">
                        <div class="alert alert-info">
                            <h5>{{ tasks[0].operation.name }}</h5>
                        </div>
                    </div>
                </div>
                <div v-else class="alert" :class="{'alert-warning': status === 'started','alert-success': status === 'finished'}">
                    <div class="row">
                        <div class="col-4"><h5>{{ tasks[0].operation.name }}</h5></div>
                        <div class="col-4 text-center">
                            <h4><b>{{ status === 'started' ? 'Начато' : 'Завершено' }}</b></h4>
                        </div>
                        <div class="col-4 text-right">
                            <router-link
                                :to="{name: 'ProductionActUpdate', params: {id: tasks[0].productionActs[0].id}}"
                            >
                                <h5>
                                    Выпуск № {{ tasks[0].productionActs[0].id }}
                                    <b-icon-chevron-double-right/>
                                </h5>
                            </router-link>
                        </div>
                    </div>
                </div>

                <div v-if="status">
                    <div v-if="tasks[0].productionActs[0].items.length" class="row mr-1">
                        <div class="col-12">
                            <items-list></items-list>
                        </div>
                    </div>

                    <temperature-after-shock-table
                        v-if="isHotStorage"
                        :production-act-items="tasks[0].productionActs[0].items"
                        v-model="tasks[0].productionActs[0].items"
                    />

                    <div
                        v-if="destination && !!producedLot && (totalTransferredCount || producedCount - totalTransferredCount > 0)"
                        style="display: flex; justify-content: space-between"
                        :class="{
                            'alert-warning': Math.abs(producedCount - totalTransferredCount) > 0.001,
                            'alert-success': Math.abs(producedCount - totalTransferredCount) <= 0.001
                         }"
                        class="alert text-center text-readable">
                        <div v-if="totalTransferredCount">
                            <a href="#" style="text-decoration: underline" @click="transferHistory()">Передано
                                {{ totalTransferredCount.toFixed(3) }} {{ measureUnit }}</a>
                        </div>
                        <div v-if="producedCount - totalTransferredCount > 0">
                            Остаток: <b>{{ (producedCount - totalTransferredCount).toFixed(3) }} {{ measureUnit }}</b>
                            <button :disabled="isDisabled()" class="btn btn-default" @click="$modal.show('transfer')">
                                в {{ destination.name }}
                                <i class="fa"
                                   :class="{'fa-angle-double-right': !isTransferring(), 'fa-spinner fa-spin': isTransferring()}"></i>
                            </button>
                        </div>
                    </div>

                    <div class="card-columns cols-2">
                        <div class="card">
                            <div class="card-header text-center text-readable">Запланировано</div>
                            <div class="card-body text-center text-readable">
                                <div>{{ requiredCount.toFixed(3) }} {{ measureUnit }}</div>
                                <div>
                                    <span>{{ moment(tasks[0].production_start_at).format('HH:mm') }}</span>
                                    -
                                    <span>{{ moment(tasks[0].production_end_at).format('HH:mm') }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header text-center text-readable">Выполнено</div>
                            <div class="card-body text-center text-readable">
                                <div>{{ producedCount.toFixed(3) }} {{ measureUnit }}</div>
                                <div>
                                    <span
                                        :class="{'alert-warning' : !tasks[0].productionActs[0].end_at}">{{
                                            moment(tasks[0].productionActs[0].start_at).format('HH:mm')
                                        }}</span>
                                    -
                                    <span>{{
                                            tasks[0].productionActs[0].end_at ? moment(tasks[0].productionActs[0].end_at).format('HH:mm') : '_ : _'
                                        }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert alert-dark text-readable text-center">
                        Осталось: {{ leftToProduce > 0 ? leftToProduce : '0.000' }} {{ measureUnit }}
                    </div>
                    <hr>
                </div>
                <div>
                    <div v-if="loadingNotReceivedTransfers || relatedTransfers.length !== 0"
                         class="card">
                        <div class="card-header">
                            <i v-if="loadingNotReceivedTransfers" class="fas fa-spinner fa-spin"></i>
                            Непринятые передачи
                        </div>
                        <div v-if="!loadingNotReceivedTransfers && relatedTransfers.length !== 0"
                             class="card-body">
                            <not-received-transfer
                                v-for="act in relatedTransfers"
                                :key="act.id"
                                :transfer="act"
                            ></not-received-transfer>
                        </div>
                    </div>
                    <div class="card mb-0">
                        <div class="card-header">
                            Технология
                        </div>
                        <div class="card-body" style="padding: 0 !important;">
                            <div class="mb-0 p-3 text-readable">{{ tasks[0].description }}</div>
                            <div class="table-responsive">
                                <table class="table table-sm table-striped table-bordered mb-0">
                                    <thead class="thead-dark">
                                    <tr>
                                        <th>Сырье</th>
                                        <th class="text-right" style="white-space: nowrap">Кол-во</th>
                                        <th class="text-right" style="white-space: nowrap">В наличии</th>
                                        <template v-if="tasks[0].productionActs.length">
                                            <th v-for="item in tasks[0].productionActs[0].items" class="text-right"
                                                style="white-space: nowrap">
                                                На
                                                {{ parseFloat(item.count ? item.count : item.plan_count).toFixed(3) }}
                                                {{ measureUnit }}
                                            </th>
                                        </template>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="s in stuff" class="text-readable">
                                        <td style="white-space: nowrap">{{ s.nomenclature.name }}</td>
                                        <td style="white-space: nowrap" class="text-right">
                                            {{ parseFloat(s.count).toFixed(3) }} {{ s.nomenclature.measure_unit.name }}
                                        </td>
                                        <td style="white-space: nowrap"
                                            :class="{'alert-danger': !s.on_stock || s.on_stock < s.count}"
                                            class="text-right">
                                            <i v-if="restsBeingLoaded" class="fas fa-spinner fa-spin"></i>
                                            <template v-else>
                                                {{ !!s.on_stock ? s.on_stock.toFixed(3) : '---' }}
                                            </template>
                                        </td>
                                        <template v-if="tasks[0].productionActs.length">
                                            <td v-for="item in tasks[0].productionActs[0].items"
                                                style="white-space: nowrap" class="text-right">
                                                {{ (s.count * (item.plan_count / requiredCount)).toFixed(3) }}
                                                {{ s.nomenclature.measure_unit.name }}
                                            </td>
                                        </template>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="card mb-0">
                        <div class="card-header">Целевая продукция</div>
                        <div class="card-body" style="padding: 0 !important;">
                            <table class="table table-sm table-striped mb-0">
                                <thead class="thead-dark">
                                <tr>
                                    <th>Наименование</th>
                                    <th class="text-right">Кол-во</th>
                                    <th>Отгрузка</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(count, id) in aggregatedPlans" class="text-readable">
                                    <td style="width: auto">
                                        <div>{{ plans[id].nomenclature.name }}</div>
                                        <div v-if="plans[id].reason.name !== 'auto'" class="alert alert-info p-0">
                                            {{ plans[id].reason.comment }}
                                        </div>
                                    </td>
                                    <td class="text-right" style="width: 150px;">{{ parseFloat(count).toFixed(3) }}
                                        {{ measureUnit }}
                                    </td>
                                    <td style="width: 150px;">{{ moment(plans[id].date).format('DD.MM.YYYY HH:mm') }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="transfer"
               :adaptive="true"
               :scrollable="true"
               :height="'auto'"
               :maxHeight="300">
            <div v-if="destination" class="card mb-0 text-readable">
                <div class="card-header">Передать в {{ destination.name }}</div>
                <div class="card-body text-center">

                    <button v-if="totalTransferredCount < requiredCount"
                            :disabled="isDisabled()"
                            @click="transfer('byTask', null)"
                            class="btn btn-lg btn-default m-5">
                        По заданию (<b>{{ (requiredCount - totalTransferredCount).toFixed(3) }} {{ measureUnit }}</b>)
                        <i v-if="isTransferring('byTask')" class="fas fa-spinner fa-spin"></i>
                        <i v-else class="fas fa-angle-double-right"></i>
                    </button>

                    <button :disabled="isDisabled()"
                            @click="transfer('all', null)"
                            class="btn btn-lg btn-default m-5">
                        Всё доступное (<b>{{ (producedCount - totalTransferredCount).toFixed(3) }} {{ measureUnit }}</b>)
                        <i v-if="isTransferring('all')" class="fas fa-spinner fa-spin"></i>
                        <i v-else class="fas fa-angle-double-right"></i>
                    </button>

                    <div class="m-5">
                        <div class="row">
                            <div class="col-8">
                                <input :disabled="isDisabled()" type="number" min="0.001" step="0.001"
                                       class="form-control" v-model="tasks[0].customTransferCount">
                            </div>
                            <div class="col-4">
                                <button :disabled="isDisabled()"
                                        @click="transfer('custom', tasks[0].customTransferCount)"
                                        class="btn btn-default">
                                    в {{ destination.name }}
                                    <i v-if="isTransferring('custom')" class="fas fa-spinner fa-spin"></i>
                                    <i v-else class="fas fa-angle-double-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
        <b-modal
            id="quality-control-act-form"
            title="Контроль качества"
            hide-footer
        >
            <quality-control
                :actualShift="actualShift"
                :storage="storage"
                :task="tasks[0]"
                :stuff="stuff"
                :produced-count="producedCount"
                :produced-lot="producedLot"
            />
        </b-modal>

        <print-modal v-if="isVegetableStorage && tasks[0]" id="print-modal" :production-task-id="tasks[0].ids[0]"/>
    </div>
</template>

<script>
import {sumBy}                                from 'lodash';
import {MATERIAL_TRANSFER_ACT_ENDPOINT}       from '@utils/endpoints';
import moment                                 from 'moment';
import MaterialTransferForm                   from '@components/materialtransferact/MaterialTransferForm';
import QualityControl                         from './QualityControl';
import NotReceivedTransfer                    from './NotReceivedTransfer';
import {stuffMixin}                           from '@mixins';
import BarcodeRepository                      from '@utils/BarcodeRepository';
import {ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR}    from '@utils/Roles';
import {mapState}                             from 'vuex';
import ErrorHandler                           from './ErrorHandler';
import NewItem                                from './productionAct/NewItem';
import ItemsList                              from './productionAct/ItemsList';
import FinishAct                              from './productionAct/FinishAct';
import TransferHistory                        from './materialTransfer/TransferHistory';
import TemperatureAfterShockTable             from '@components/_common/TemperatureAfterShockTable';
import PrintModal                             from './components/PrintModal.vue';
import {HOT_STORAGE_ID, VEGETABLE_STORAGE_ID} from '@constants';
import {ProductionActService}                 from '@services';
import Downloader                             from '@utils/Downloader';

export default {
    name: 'TaskDetails',
    mixins: [stuffMixin],
    components: {
        MaterialTransferForm,
        QualityControl,
        NotReceivedTransfer,
        ItemsList,
        TemperatureAfterShockTable,
        PrintModal,
    },
    computed: {
        ...mapState({
            tasks: state => state.storage_senior_dashboard.selectedTasks,
            restsBeingLoaded: state => state.storage_senior_dashboard.restsBeingLoaded,
            plans: state => state.storage_senior_dashboard.plans,
            actualShift: state => state.storage_senior_dashboard.actualShift,
            storage: state => state.storage_senior_dashboard.storage,
            loadingNotReceivedTransfers: state => state.storage_senior_dashboard.loadingNotReceivedTransfers,
        }),
        relatedTransfers() {
            let result = [];
            this.$store.state.storage_senior_dashboard.notReceivedTransfers.map(t => {
                t.items.map(i => {
                    if (!!this.stuff[i.nomenclatureLot.nomenclature.id]) {
                        result.push(t);
                    }
                });
            });

            return result;
        },
        destination() {
            if (this.storage.packing) {
                // Не передаем вручную из фасовки
                return null;
            }

            if (!!this.tasks[0].parent && this.tasks[0].parent.storage.id !== this.storage.id) {
                return this.tasks[0].parent.storage;
            }
        },
        producedCount() {
            return parseFloat(sumBy(this.tasks, t => sumBy(t.productionActs, a => sumBy(a.items, i => parseFloat(i.count)))).toFixed(3));
        },
        requiredCount() {
            return parseFloat(sumBy(this.tasks, t => parseFloat(t.count)).toFixed(3));
        },
        leftToProduce() {
            return parseFloat((this.requiredCount - this.producedCount).toFixed(3));
        },
        totalTransferredCount() {
            return sumBy(this.tasks, t => sumBy(t.productionActs, pa => sumBy(pa.materialTransferActs, a => a.canceled ? 0 : sumBy(a.items, i => {
                return i.nomenclatureLot.nomenclature_id === t.nomenclature.id ? parseFloat(i.count) : 0;
            }))));
        },
        stuff() {
            return this.getStuff(this.tasks);
        },
        aggregatedPlans() {
            let plans = {};
            this.tasks.map(t => {
                Object.keys(t.plans).map(id => {
                    let count = parseFloat(t.plans[id]);
                    if (!(!!plans[id])) {
                        plans[id] = count;
                    } else {
                        plans[id] += count;
                    }
                });
            });

            return plans;
        },
        producedLot() {
            if (this.tasks[0].productionActs.length === 0) {
                return null;
            }

            return this.tasks[0].productionActs[0].produced_lot;
        },
        measureUnit() {
            return this.tasks[0].nomenclature.measure_unit.name;
        },
        transferInfo() {
            let info = [];
            this.tasks.map(t => {
                t.productionActs.map(pa => {
                    pa.materialTransferActs.map(a => {
                        info.push(a);
                    });
                });
            });

            return info;
        },
        status() {
            if (!this.tasks[0].productionActs.length) {
                return null;
            }

            if (this.tasks[0].productionActs[0].assume_finished || (this.producedCount >= this.requiredCount)) {
                if (!!this.destination && this.producedCount > this.totalTransferredCount) {
                    return 'started'
                }

                if (this.storage.packing && this.producedCount > this.totalTransferredCount) {
                    return 'started'
                }

                return 'finished';
            }

            if (this.producedCount < this.requiredCount || this.producedCount > this.totalTransferredCount) {
                return 'started';
            }
        },

        isHotStorage() {
            return this.storage && this.storage.id === HOT_STORAGE_ID;
        },
        isVegetableStorage() {
            return this.storage && this.storage.id === VEGETABLE_STORAGE_ID;
        },
    },
    data() {
        return {
            params: {
                modal: {
                    adaptive: true,
                    height: 'auto',
                    width: 900,
                    scrollable: true,
                    pivotY: 0
                },
            }
        };
    },
    methods: {
        moment,
        closeTaskWindow() {
            this.$store.commit('storage_senior_dashboard/set_selected_tasks', []);
        },
        newItem() {
            this.$modal.show(NewItem, {}, this.params.modal, {
                closed: () => this.$forceUpdate()
            });
        },
        assumeFinished() {
            this.$modal.show(FinishAct, {}, this.params.modal, {
                closed: () => this.$forceUpdate()
            });
        },
        getTransferData(mode, count) {
            if (this.tasks[0].productionActs.length === 0 || !(this.producedLot)) {
                return null;
            }

            let toTransfer = 0;
            switch (mode) {
                case 'byTask':
                    toTransfer = this.requiredCount - this.totalTransferredCount;
                    break;
                case 'all':
                    toTransfer = this.producedCount - this.totalTransferredCount;
                    break;
                case 'custom':
                    if (count !== null) {
                        toTransfer = parseFloat(count);
                        if (toTransfer > (this.producedCount - this.totalTransferredCount)) {
                            toTransfer = this.producedCount - this.totalTransferredCount;
                        }
                    }
                    break;
            }

            if (toTransfer <= 0) {
                return null;
            }

            return {
                storage_from_id: this.storage.id,
                storage_to_id: this.destination.id,
                production_act_id: this.tasks[0].productionActs[0].id,
                items: [
                    {
                        nomenclature_lot_id: this.producedLot.id,
                        count: (toTransfer).toFixed(3),
                        box: {
                            name: BarcodeRepository.boxPrefixes[1] +
                                Math.floor(Math.random() * (99999 - 10000 + 1) + 10000).toString()
                        }
                    }
                ]
            };
        },
        transferHistory() {
            this.$modal.show(TransferHistory, {
                acts: this.transferInfo,
                measureUnit: this.measureUnit,
            }, {
                adaptive: true,
                height: 'auto',
                width: 900,
                scrollable: true,
            })
        },
        transfer(mode = 'byTask', count = null) {

            let data = this.getTransferData(mode, count);
            if (data === null) {
                this.$modal.hide('transfer');
                return;
            }

            // Важный момент! Так мы сохраняем ссылку на нужную таску в замыкании запроса
            // Решает кейс, когда начали выпуск и тут же переключились на другую таску
            // - после завершения запроса акт подсовывался не туда
            let tasksLink = this.tasks;

            tasksLink.map(t => {
                t.transferring = true;
                t.transferringMode = mode;
                this.$store.dispatch('storage_senior_dashboard/updateTask', t);
            });
            this.$forceUpdate(); // Небольшой замес с реактивностьб, поэтому так

            this.$http.post(MATERIAL_TRANSFER_ACT_ENDPOINT, data, {
                params: {
                    with: ['items.nomenclatureLot'],
                    without_loading: true
                }
            })
                .then(response => {
                    tasksLink[0].productionActs[0].materialTransferActs.push(response.data);
                    this.$store.dispatch('storage_senior_dashboard/updateTask', tasksLink[0]);
                    this.$toast.success('Передано ' + sumBy(data.items, 'count') + ' ' + this.measureUnit);
                    this.$modal.hide('transfer');
                }).catch(response => {
                ErrorHandler.assert(response, 'Ошибка передачи ' + tasksLink[0].nomenclature.name);
            }).finally(() => {
                tasksLink.map(t => {
                    t.transferring = false;
                    this.$store.dispatch('storage_senior_dashboard/updateTask', t);
                });
                this.$store.dispatch('storage_senior_dashboard/loadRests');
                this.$forceUpdate();// Небольшой замес с реактивностьб, поэтому так
            });
        },
        accessForCreateQualityControlAct() {
            return this.$auth
                .user()
                .roles
                .some(role => [ROLE_ADMIN, ROLE_SHIFT_SUPERVISOR].includes(role));
        },
        isSavingProducedCount() {
            // Небольшой замес с реактивностью, поэтому так
            let result = false;
            this.tasks.map(t => {
                if (!!t.savingProducedCount) {
                    result = true;
                }
            });

            return result;
        },
        isCreatingProductionAct() {
            // Небольшой замес с реактивностью, поэтому так
            let result = false;
            this.tasks.map(t => {
                if (!!t.creatingProductionAct) {
                    result = true;
                }
            });

            return result;
        },
        isTransferring(mode = null) {
            // Небольшой замес с реактивностью, поэтому так
            let result = false;
            let resultMode = null;
            this.tasks.map(t => {
                if (!!t.transferring) {
                    result = true;
                }

                if (!!t.transferringMode) {
                    resultMode = t.transferringMode;
                }
            });

            if (mode !== null) {
                result = result && resultMode === mode;
            }

            return result;
        },
        isActBlocked() {
            // Небольшой замес с реактивностью, поэтому так
            let result = false;
            this.tasks.map(t => {
                t.productionActs.map(a => {
                    if (!!a.blocked) {
                        return true;
                    }
                });
            });

            return result;
        },
        isBlocked() {
            let result = false;
            this.tasks.map(t => {
                if (!!t.is_blocked) {
                    result = true;
                }
            });

            return result;
        },
        isDisabled() {
            // Небольшой замес с реактивностью, поэтому так
            return this.isActBlocked() || this.isCreatingProductionAct() || this.isSavingProducedCount() || this.isTransferring() || this.isBlocked();
        },
        getMaterialTransferInvoice() {
            ProductionActService.getMaterialTransferInvoice(this.tasks[0].productionActs[0].id).then((invoice) => {
                Downloader.download(invoice, 'application/pdf', 'Накладная на Mini K.pdf');
            }).catch((response) => {
                // Парсим arraybuffer в строку
                const textDecoder = new TextDecoder('UTF-8');
                const error = textDecoder.decode(response.data);

                this.$bvToast.toast(error, {variant: 'danger'});
            });
        }
    }
}
</script>

<style scoped>
.text-readable {
    font-size: 18px;
}
</style>
