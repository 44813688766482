<template>
    <div>
        <div v-if="material === null" class="p-2 text-center" style="min-height: 300px; display: flex; align-items: center; justify-content: center">
            <button class="btn btn-lg btn-default m-5" @click="material = true">Сырье</button>
            <button class="btn btn-lg btn-default m-5"
                    @click="material = false; toAct()">Продукция</button>
        </div>
        <template v-else-if="material === true">
            <div class="card-header">
                <button class="btn btn-lg btn-ghost-dark m-1" @click="material = null;"><i class="fas fa-long-arrow-alt-left"></i></button>
            </div>
            <table class="table table-sm table-striped mb-0 text-readable">
                <thead class="thead-dark">
                <tr>
                    <th class="text-right">
                        <b-checkbox
                            @change="toggleAll($event)"
                            :indeterminate="Object.keys(selected).length !== 0 && Object.keys(selected).length !== Object.keys(stuff).length"
                            :checked="Object.keys(selected).length === Object.keys(stuff).length"
                        ></b-checkbox></th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="s in stuff" @click.stop="toggle(s, !(!!selected[s.nomenclature.id]))">
                    <td class="text-right"><b-checkbox @input="toggle(s, $event)" :checked="!!selected[s.nomenclature.id]"></b-checkbox></td>
                    <td>{{s.nomenclature.name}}</td>
                    <td class="text-right">{{parseFloat(s.count).toFixed(3)}} {{s.nomenclature.measure_unit.name}}</td>
                </tr>
                </tbody>
            </table>
            <div class="card-footer text-right">
                <button
                    @click="toAct()"
                    :disabled="Object.keys(selected).length === 0"
                    class="btn btn-lg btn-success">Перейти к акту</button>
            </div>
        </template>
    </div>
</template>

<script>

    export default {
        name: "QualityControl",
        props: {
            actualShift: {
                type: Object,
                required: true
            },
            storage: {
                type: Object,
                required: true
            },
            task: {
                type: Object,
                required: true
            },
            producedCount: {
                type: Number,
                required: true
            },
            producedLot: {
                type: Object,
                required: true
            },
            stuff: {
                type: Object,
                nullable: true,
                required: true
            }
        },
        data() {
            return {
                material: null,
                selected: {},
            };
        },
        computed: {
            allSelected() {
                return Object.keys(this.selected).length === Object.keys(this.stuff).length;
            },
            selectionIndeterminate() {
                return Object.keys(this.selected).length && Object.keys(this.selected).length !== Object.keys(this.stuff).length;
            },
        },
        methods: {
            toggleAll(value) {
                Object.keys(this.stuff).map(k => {
                    this.toggle(this.stuff[k], value);
                });
            },
            toggle(s, value) {
                if (value) {
                    this.$set(this.selected, s.nomenclature.id, s);
                } else {
                    this.$delete(this.selected, s.nomenclature.id);
                }

                this.$forceUpdate();
            },
            clear() {
                this.material = null;
                this.selected = {};
            },
            toAct() {
                this.$router.push({
                    name: 'QualityControlActCreate',
                    params: {
                        transfer: {
                            date: this.actualShift.date,
                            shift: this.actualShift.shift,
                            storage: this.storage,
                            items: this.getItems()
                        }
                    }
                })
            },
            getItems() {
                if (this.material) {
                    return Object.keys(this.selected).map(k => {
                        return {
                            count: this.selected[k].count,
                            nomenclatureId: this.selected[k].nomenclature.id,
                            nomenclatureLot: null,
                        };
                    });
                } else {
                    return [
                        {
                            count: this.producedCount,
                            nomenclatureId: this.task.nomenclature.id,
                            nomenclatureLotId: this.producedLot ? this.producedLot.id : null
                        }
                    ];
                }
            },
        }
    }
</script>

<style scoped>

</style>
