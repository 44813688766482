<template>
    <b-modal title="Печать этикетки" ref="modal" v-bind="$attrs" @ok.prevent="sendToPrint" @hide="onClose" ok-variant="success" ok-title="Печать" cancel-title="Отмена">
        <div class="form-group">
            <printer-picker v-model="printer"/>
            <small class="form-text text-muted">Принтер</small>
        </div>
        <div class="form-group">
            <input v-model="labelCount" type="number" class="form-control">
            <small class="form-text text-muted">Количество этикеток</small>
        </div>
        <errors-bag-list/>
    </b-modal>
</template>

<script>
import ErrorsBagList from '@components/_common/ErrorsBagList.vue';
import ErrorsBag from '@utils/errorsBag.js';
import PrinterPicker from '@components/_common/devices/PrinterPicker.vue';
import {PrinterQueueService} from '@services';

export default {
    name: 'PrintModal',
    components: {
        PrinterPicker,
        ErrorsBagList,
    },
    props: {
        productionTaskId: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            printer: null,
            labelCount: null,
        };
    },
    methods: {
        sendToPrint() {
            ErrorsBag.discard();
            PrinterQueueService.sendToPrintProductionTaskLabel({
                production_task_id: this.productionTaskId,
                printer_id: this.printer?.id,
                label_count: this.labelCount,
            }).then(() => {
                this.$bvToast.toast('Этикетки успешно отправлены на печать', {variant: 'success'});
                this.$refs['modal'].hide();
            }).catch((response) => {
                ErrorsBag.fill(Object.values(response.data.errors).flat());
            });
        },
        onClose() {
            this.printer = null;
            this.labelCount = null;
            ErrorsBag.discard();
        },
    },
}
</script>
