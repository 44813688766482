<template>
    <div>
        <template v-if="hasAccess()">
            <div v-if="loadingBarrier > 0" class="dashboard-flex">
                <div class="alert alert-info"><i class="fas fa-spinner fa-spin"></i></div>
            </div>
            <div v-else-if="storage && actualShift" style="display: flex; flex-direction: row; justify-content: space-between;">
                <div class="sticky" :style="{'width': taskListMinimized ? '4%' : '33%'}">
                    <task-list
                        ref="task-list"
                        @tasks-selected="selectedTasks = $event"
                        @toggleMinimized="taskListMinimized = $event"></task-list>
                </div>
                <div :style="{'width': taskListMinimized ? '96%' : '67%'}" class="sticky pl-1" style="transition: all .25s">
                    <task-details v-if="selectedTasks.length"></task-details>
                    <shift-stats v-else></shift-stats>
                </div>
            </div>
            <div v-else-if="storages.length" class="dashboard-flex flex-row flex-wrap">
                <button
                    v-for="storage in storages"
                    class="btn btn-primary btn-lg m-2"
                    @click="selectStorage(storage)">{{storage.name}}</button>
            </div>
        </template>
        <template v-else>
            <div class="dashboard-flex">
                <div class="alert alert-warning mt-2">Недоступно</div>
            </div>
        </template>
        <modal
            name="MaterialTransferReceiveForm"
            :adaptive="true"
            :scrollable="true"
            :height="'auto'"
            :maxHeight="300"
            @before-close="beforeCloseMaterialTransferActToReceive"
        >
            <div class="card mb-0">
                <div class="card-header">Принять
                    <span class="pull-right"><button @click="$modal.hide('MaterialTransferReceiveForm')" class="btn btn-sm btn-default"><i class="fas fa-times"></i></button></span>
                </div>
                <div class="card-body" style="padding: 0 !important;">
                    <material-transfer-receive-form
                        :on-success="(act) => {reloadNotReceivedTransfersAfterClose = true; $modal.hide('MaterialTransferReceiveForm');}"
                        :on-error="(errors) => {reloadNotReceivedTransfersAfterClose = false;}"
                        :go-back-callback="() => {$modal.hide('MaterialTransferReceiveForm')}"
                        :act-to-show="transferToReceive"></material-transfer-receive-form>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import TaskList from "./TaskList";
import TaskDetails from "./TaskDetails";
import ShiftStats from "./ShiftStats";
import MaterialTransferReceiveForm from "../../materialtransferact/MaterialTransferReceiveForm";
import {PRODUCTION_SHIFT_ENDPOINT, STORAGE_ENDPOINT} from "../../../utils/endpoints";
import {sortBy} from 'lodash';
import {mapState} from 'vuex';
import ErrorHandler from "./ErrorHandler";
import {Guard} from "./Guard";
import moment from 'moment';
import {ROLE_STORAGE_SENIOR} from "../../../utils/Roles";

export default {
    name: "Dashboard",
    components: {
        TaskList,
        TaskDetails,
        ShiftStats,
        MaterialTransferReceiveForm
    },
    data() {
        return {
            taskListMinimized: false,
            loadingBarrier: 0,
            storages: [],

            reloadNotReceivedTransfersAfterClose: false,
        };
    },
    computed: {
        ...mapState({
            storage: state => state.storage_senior_dashboard.storage,
            actualShift: state => state.storage_senior_dashboard.actualShift,
            transferToReceive: state => state.storage_senior_dashboard.transferToReceive,
            selectedTasks: state => state.storage_senior_dashboard.selectedTasks,
            plans: state => state.storage_senior_dashboard.plans,
            filters: state => state.storage_senior_dashboard.filters,
        })
    },
    watch: {
        storages(val) {
            if (val.length === 1) {
                this.selectStorage(val[0]);
            }
        },
        transferToReceive(val) {
            if (val) {
                this.$modal.show('MaterialTransferReceiveForm');
            } else {
                this.$modal.hide('MaterialTransferReceiveForm');
            }
        }
    },
    methods: {
        moment,
        hasAccess() {
            return Guard.canAccess(this.$auth.user());
        },
        beforeCloseMaterialTransferActToReceive() {
            if (this.reloadNotReceivedTransfersAfterClose) {
                this.$store.dispatch('storage_senior_dashboard/loadNotReceivedTransfers');
                this.$store.dispatch('storage_senior_dashboard/loadRests');
            }

            this.reloadNotReceivedTransfersAfterClose = false;
            this.$store.commit('storage_senior_dashboard/set_transfer_to_receive', null);
        },
        selectStorage(storage) {
            this.$store.commit('storage_senior_dashboard/set_storage', storage);
            this.$store.dispatch('storage_senior_dashboard/reload');
        },
        selectActualShift(actualShift) {
            this.$store.commit('storage_senior_dashboard/set_actual_shift', actualShift);
        },
        fetchStorages() {
            this.loadingBarrier++;
            this.$http.get(STORAGE_ENDPOINT, {
                params: {
                    filters: {
                        allowed_roles: [ROLE_STORAGE_SENIOR],
                        selectable_for_task_list: 1,
                    },
                    limit: 100,
                    without_loading: true,
                }
            }).then(response => {
                this.storages = sortBy(response.data.data.data, 'name');
            })
                .catch(this.$root.responseError)
                .finally(() => {
                    this.loadingBarrier--;
                });
        },
        fetchShift() {
            this.loadingBarrier++;
            this.$http.get(PRODUCTION_SHIFT_ENDPOINT + '/current', {
                params: {
                    without_loading: true,
                }
            })
                .then(response => {
                    this.selectActualShift(response.data);
                }).catch(response => {
                ErrorHandler.assert(response, 'Ошибка получения смены');
            }).finally(() => {
                this.loadingBarrier--;
            });
        },
        window() {
            return window;
        },
    },
    mounted() {

        if (!Guard.canAccess(this.$auth.user())) {
            return;
        }

        if (!this.storages.length) {
            this.fetchStorages();
        }

        if (!this.actualShift) {
            this.fetchShift();
        }

        this.$store.commit('set_header_toolbar', 'storage_senior_dashboard');
    },
    destroyed() {
        this.$store.commit('set_empty_toolbar');
    }
}
</script>

<style scoped>
.dashboard-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.sticky {
    position: sticky;
    top: 55px;
}
</style>
